import React, { useState, useRef, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import Container from '@mui/material/Container';
import { Grid, TextField, Card, CardContent, Button, Typography, MenuItem, InputLabel, FormControl, FormHelperText, List, ListItem, ListItemText, Box } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import AddStaffAccount from '../../components/admin/Account/AddStaffAccount';
import PageHeader from '../../components/global/PageHeader';
import AccountList, { AccountListRef } from '../../components/admin/Account/AccountList';
import ProgressIndicator from '../../components/global/ProgressIndicator';

import { SearchUsersByField, SearchUsersByFieldVariables, SearchAllUserRoles } from '../../models/GeneratedModels';
import { searchUserByField_Gql } from '../../gql/user/searchUserByField';
import { searchAllUserRoles_Gql } from '../../gql/user/searchAllUserRoles';
import PermissionDeniedSplash from 'pages/PermissionDeniedSplash';

import { useAuth } from 'hooks/useAuth';

interface SearchProperties {
  search: string;
  field: string;
}

const charLimits = {
  email: 4,
  default: 2
};

const roleOptions = [
  'All',
  'Administrator',
  'ECHOStaff',
  'Development',
  'Implementation',
  'Evaluation',
  'Partner',
  'Registrant'
];

const Accounts = () => {
  const [searchProperties, setSearchProperties] = useState<SearchProperties>({ search: '', field: 'lastName' });
  const [searchWasRun, setSearchWasRun] = useState(false);
  const [isFieldChangeLoading, setIsFieldChangeLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const accountListRef = useRef<AccountListRef>(null);
  const [roleCounts, setRoleCounts] = useState<Record<string, number>>({
    All: 0,
    Administrator: 0,
    ECHOStaff: 0,
    Development: 0,
    Implementation: 0,
    Evaluation: 0,
    Partner: 0,
    Registrant: 0
  });
  const [allUserRoleCounts, setAllUserRoleCounts] = useState<Record<string, number>>({
    All: 0,
    Administrator: 0,
    ECHOStaff: 0,
    Development: 0,
    Implementation: 0,
    Evaluation: 0,
    Partner: 0,
    Registrant: 0
  });

  const [searchAccounts, { loading, data }] = useLazyQuery<SearchUsersByField, SearchUsersByFieldVariables>(
    searchUserByField_Gql,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [fetchAllRoles, { loading: loadingAllUserRoles, data: dataAllUserRoles }] = useLazyQuery<SearchAllUserRoles>(searchAllUserRoles_Gql, {
    fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      const counts = {
        All: 0,
        Administrator: 0,
        ECHOStaff: 0,
        Development: 0,
        Implementation: 0,
        Evaluation: 0,
        Partner: 0,
        Registrant: 0
      };
      
      type RoleKeys = keyof typeof counts;
      
      data.allUserRolesCount.forEach((role) => {
        const roleName = role.role_name as RoleKeys;
        if (roleName in counts) {
          counts[roleName] = role.count;
          counts.All += role.count;
        }
      });
      
      setRoleCounts(counts);
      setAllUserRoleCounts(counts);
    }
  });

  const auth = useAuth();
  const isAuthorized = () => {
    return auth.user?.isAlbatross || auth.user?.isAdmin || auth.user?.isEchoStaff || auth.user?.isDevelopmentStaff || auth.user?.isImplementationStaff || auth.user?.isEvaluationStaff; // auth.user?.isPartner;
  };

  const validateInput = (): boolean => {
    const limit = searchProperties.field === 'email' ? charLimits.email : charLimits.default;
    if (searchProperties.search.length < limit) {
      setError(`Search term must be at least ${limit} characters.`);
      return false;
    } else {
      setError(null);
      return true;
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !error) {
      e.preventDefault();
      onSearch();
    }
  };  

  const onSearch = () => {
    if (!validateInput()) {
      return;
    }
    setSearchWasRun(true);
    searchAccounts({
      variables: {
        search: searchProperties.search,
        field: searchProperties.field,
      },
      onCompleted: () => {
        // If searching by role, focus the corresponding tab
        if (searchProperties.field === 'role' && accountListRef.current) {
          accountListRef.current.setActiveTab(searchProperties.search);
        }
      }
    });
  };

  const onFormElementChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchProperties({
      ...searchProperties,
      search: e.target.value,
    });

    setError(null);
  };

  const onFieldChange = (event: SelectChangeEvent<string>) => {
    const newField = event.target.value;
    // Only show loading when switching to/from role field
    if (searchProperties.field === 'role' || newField === 'role') {
      setIsFieldChangeLoading(true);
      setTimeout(() => setIsFieldChangeLoading(false), 300);
    }
    
    setSearchProperties({
      ...searchProperties,
      field: newField,
    });
    setError(null);
  };

  const onReset = () => {
    setSearchWasRun(false);
    setSearchProperties({ search: '', field: 'lastName' });
    setError(null);
    setRoleCounts(allUserRoleCounts);
  };

  const users = data?.searchUsersByField ?? [];

  // Update role counts whenever needed
  const updateRoleCounts = () => {
    if (accountListRef.current) {
      const newRoleCounts = Object.keys(roleCounts).reduce((acc, role) => {
        acc[role] = accountListRef.current!.getCountForRole(role);
        return acc;
      }, {} as Record<string, number>);
      setRoleCounts(newRoleCounts);
    }
  };

  // Add useEffect to update role counts when users data changes
  useEffect(() => {
    if (users.length > 0) {
      updateRoleCounts();
    }
  }, [users]);

  // Add this new function to handle role selection
  const handleRoleClick = (role: string) => {
    if (!searchWasRun) {
      setSearchProperties({
        field: 'role',
        search: role
      });
      // Use setTimeout to ensure state is updated before search
      setTimeout(() => {
        onSearch();
      }, 0);
    } else if (accountListRef.current) {
      accountListRef.current.setActiveTab(role);
    }
  };

  // Add this effect after other useEffects
  useEffect(() => {
    fetchAllRoles();
  }, [fetchAllRoles]);

  const renderSearchInput = () => {
    if (isFieldChangeLoading) {
      return <ProgressIndicator isOpen={true} title="Updating Search Field..." />;
    }

    if (searchProperties.field === 'role') {
      return (
        <FormControl fullWidth error={!!error}>
          <InputLabel size="small">Search Role</InputLabel>
          <Select
            size="small"
            value={searchProperties.search}
            label="Search Role"
            onChange={(e) => {
              setSearchProperties({
                ...searchProperties,
                search: e.target.value
              });
              setError(null);
            }}
          >
            {roleOptions.map((role) => (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{error || ' '}</FormHelperText>
        </FormControl>
      );
    }

    return (
      <TextField
        sx={{ width: '100%' }}
        size='small'
        label="Search"
        value={searchProperties.search}
        onChange={onFormElementChange}
        error={!!error}
        helperText={error || ' '}
        onKeyDown={onKeyDown}
      />
    );
  };

  if (!isAuthorized()) {
    return <PermissionDeniedSplash />;
  }

  return (
    (!isAuthorized()) ? (
      <PermissionDeniedSplash />
    ) : (
      <>
      {isAuthorized() && (
      <>
      <ProgressIndicator isOpen={loading || loadingAllUserRoles} title="Loading..." />
      <Container maxWidth="xl" sx={{ p: 10, mb: 6 }}>
        <Grid container spacing={2}>
          {/* Left Column */}
          <Grid item xs={8} sx={{ display: 'flex', flexDirection: 'column' }}>
            <PageHeader title="Accounts" />
            <AddStaffAccount />
            
            <Card sx={{ textAlign: 'left', mt: 'auto' }}>
              <CardContent>
                <Typography sx={{ marginBottom: '20px' }} gutterBottom variant="h5" component="div">
                  User Search
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel>Search Field</InputLabel>
                      <Select
                        size='small'
                        value={searchProperties.field}
                        label="Search Field"
                        onChange={onFieldChange}
                      >
                        <MenuItem value="lastName">Last Name</MenuItem>
                        <MenuItem value="firstName">First Name</MenuItem>
                        <MenuItem value="email">Email</MenuItem>
                        <MenuItem value="organization">Organization</MenuItem>
                        <MenuItem value="role">Role</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    {renderSearchInput()}
                  </Grid>
                  <Grid item xs={4}>
                    <Button onClick={onSearch} sx={{ width: '40%' }} color="primary" variant="contained" disabled={!!error}>
                      Search
                    </Button>
                    <Button onClick={onReset} sx={{ width: '40%', ml: 2 }} color="primary" variant="outlined">
                      Reset
                    </Button>
                  </Grid>
                </Grid>
                <Typography variant="caption" sx={{ mt: 0.5 }}>Select a field and enter a search term</Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Right Column */}
          <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Card sx={{ textAlign: 'left', mt: 'auto' }}>
              <CardContent>
                <Typography sx={{ marginBottom: '20px' }} gutterBottom variant="h5" component="div">
                  User-Roles Summary
                </Typography>
                <List>
                  {['All', 'Administrator', 'ECHOStaff', 'Partner', 'Registrant'].map((role) => (
                    <ListItem key={role} sx={{ py: 0.5 }}>
                      <ListItemText 
                        primary={
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="body2">{role}</Typography>
                            {roleCounts[role] > 0 ? (
                              <Typography 
                                variant="body2" 
                                component="span"
                                sx={{ 
                                  cursor: role !== 'All' && role !== 'Registrant' ? 'pointer' : 'default', 
                                  '&:hover': { 
                                    textDecoration: role !== 'All' && role !== 'Registrant' ? 'underline' : 'none',
                                    color: role !== 'All' && role !== 'Registrant' ? 'primary.main' : 'inherit'
                                  }
                                }}
                                onClick={() => role !== 'All' && role !== 'Registrant' && handleRoleClick(role)}
                              >
                                {roleCounts[role]}
                              </Typography>
                            ) : (
                              <Typography variant="body2" component="span">
                                0
                              </Typography>
                            )}
                          </Box>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            {!loading && 
              <AccountList 
              ref={accountListRef}
              users={users} 
              searchWasRun={searchWasRun}
            />
            } 
          </Grid>
        </Grid>
      </Container>
    </>
    )}
    </>
  ));
};

export default Accounts;
