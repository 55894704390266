// import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tabs, Tab, Box } from '@mui/material';
import { Tooltip, Alert, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useState, forwardRef, useImperativeHandle } from 'react';

import { SearchUsersByName_searchUsersByName } from '../../../models/GeneratedModels';
import ViewAccountLink from './ViewAccountLink';
import ManageUserPermissions from '../../account/Permissions/ManageUserPermissions';
import EditStaffAccount from '../Account/EditStaffAccount';
import ChangePassword from '../Account/ChangePassword';
import ProgressIndicator from 'components/global/ProgressIndicator';

// Update the AccountListRef interface
export interface AccountListRef {
  getCountForRole: (role: string) => number;
  setActiveTab: (role: string) => void;
}

interface AccountListProps {
  users: SearchUsersByName_searchUsersByName[];
  searchWasRun: boolean;
}

const AccountList = forwardRef<AccountListRef, AccountListProps>(({ users, searchWasRun }, ref) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const hasEmptySearch = searchWasRun && users.length === 0;

  const getCountForRole = (role: string): number => {
    const count = role === 'All' ? users.length : 
      users.filter(user => 
        user.userRolesForDisplay && user.userRolesForDisplay.indexOf(role) >= 0
      ).length;
    
    return count;
  };

  // Add this function to find the tab index by role name
  const getTabIndexByRole = (role: string): number => {
    return tabs.indexOf(role);
  };

  // Update useImperativeHandle to include loading state in setActiveTab
  useImperativeHandle(ref, () => ({
    getCountForRole,
    setActiveTab: (role: string) => {
      const tabIndex = getTabIndexByRole(role);
      if (tabIndex !== -1) {
        setLoading(true);
        setSelectedTab(tabIndex);
        // Simulate a small delay to show loading state
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    }
  }));

  if (hasEmptySearch) {
    return <Alert severity="info">No results found</Alert>;
  }

  // const onSaveClick = () => {
  //   console.log('save');
  // };

  const userIsAdmin = (user: SearchUsersByName_searchUsersByName) => {
    if (!user || !user.userRolesForDisplay) return false;

    return user.userRolesForDisplay.indexOf('ECHOStaff') >= 0;
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setLoading(true);
    setSelectedTab(newValue);
    // Simulate a small delay to show loading state
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const filterUsersByRole = (users: SearchUsersByName_searchUsersByName[], role: string) => {
    if (role === 'All') return users;
    return users.filter(user => 
      user.userRolesForDisplay && user.userRolesForDisplay.indexOf(role) >= 0
    );
  };

  const tabs = [
    'All',
    'Administrator',
    'ECHOStaff',
    'Development',
    'Implementation',
    'Evaluation',
    'Partner',
    'Registrant'
  ];

  return (
    <>
      <ProgressIndicator isOpen={loading} title="Loading..." />
      {searchWasRun && (
        <>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs 
              value={selectedTab} 
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                '& .MuiTab-root': {
                  border: '1px solid #e0e0e0',
                  borderRadius: '4px',
                  marginX: '4px',
                  minHeight: '40px',
                  '&:first-of-type': {
                    marginLeft: 0,
                  },
                  '&:last-of-type': {
                    marginRight: 0,
                  },
                },
              }}
            >
              {tabs.map((tab, index) => (
                <Tab 
                  key={tab} 
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <span>{tab}</span>
                      <span style={{ 
                        backgroundColor: '#f5f5f5', 
                        borderRadius: '12px', 
                        padding: '2px 8px',
                        fontSize: '0.75rem',
                        color: '#666'
                      }}>
                        {getCountForRole(tab)}
                      </span>
                    </Box>
                  } 
                />
              ))}
            </Tabs>
          </Box>
          <TableContainer component={Paper}>
            <Table sx={{ width: '100%' }}>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell align="left">Credentials</TableCell>
                  <TableCell align="left">Organization</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterUsersByRole(users, tabs[selectedTab]).map((user) => (
                  <TableRow key={user.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      <ViewAccountLink userId={user.id} name={`${user.lastName}, ${user.firstName}`} email={user.email} />
                      <Typography variant="caption">{user.userRolesForDisplay}</Typography>
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell align="left">{user.userCredentialsForDisplay}</TableCell>
                    <TableCell align="left">{user.organization}</TableCell>
                    <TableCell sx={{ width: '18px' }}>
                      <Tooltip title={`Edit ${user.firstName}`}>
                        {userIsAdmin(user) ? (
                          <EditStaffAccount user={user} />
                        ) : (
                          <RouterLink to={`/admin/account/edit/${user.id}`}>
                            <EditIcon color="primary" sx={{ fontSize: '16px' }} />
                          </RouterLink>
                        )}
                      </Tooltip>
                    </TableCell>
                    <TableCell sx={{ width: '18px' }}>
                      <ChangePassword userId={user.id} firstName={user.firstName ?? ''} lastName={user.lastName ?? ''} />
                    </TableCell>
                    <TableCell sx={{ width: '18px' }}>
                      <Tooltip title={`Manage permissions for ${user.firstName}`}>
                        <ManageUserPermissions
                          userId={user.id}
                          firstName={user.firstName ?? ''}
                          lastName={user.lastName ?? ''}
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell sx={{ width: '18px' }}>
                      <Tooltip title={`View Cohorts as ${user.firstName}`}>
                        <RouterLink to="/NotImplemented" target="_blank">
                          <ArrowForwardIosIcon color="primary" sx={{ ml: 1, fontSize: '16px' }} />
                        </RouterLink>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
});

export default AccountList;
